import React from "react";

const ArrowRightIcon = () => (
  <svg
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path d="M5 12H19" stroke="currentColor" stroke-width="2" />
    <path d="M12 19L19 12L12 5" stroke="currentColor" stroke-width="2" />
  </svg>
);

export default ArrowRightIcon;

/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import ArrowRight from "@components/icons/arrowRight";
import { navigate } from "gatsby";
import { Location } from "@reach/router";

export const isBrowser = typeof window !== "undefined" && window;

export default () => {
  return (
    <button
      onClick={() => {
        return window.history.length === 1
          ? navigate("/")
          : window.history.go(-1);
      }}
      sx={styles}
    >
      <ArrowRight />
      {isBrowser ? (window.history.length === 1 ? "Home" : "Back") : "Back"}
    </button>
  );
};

const styles = {
  fontSize: rem(18),
  fontWeight: 600,
  svg: {
    transform: "rotate(180deg) translateY(-5px)",
    marginRight: rem(3),
  },
};

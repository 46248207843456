/** @jsx jsx */
import { jsx } from "theme-ui";
import { rem } from "@src/theme";
import SendIcon from "@components/icons/send";
import BookIcon from "@components/icons/book";
import Container from "@components/Container";
import { Link } from "gatsby";
import userIllustration from "@src/assets/images/usersfooter.svg";
const Footer = ({}) => {
  return (
    <footer
      sx={{
        width: "100%",
      }}
    >
      <div
        sx={{
          width: "100%",
          display: "flex",
          flexDirection: ["column", null, "row"],
        }}
      >
        <Link to="/about" sx={blockLinkStyles} className="link-hover bg-hover">
          <BookIcon />
          <span>What is SEES?</span>
        </Link>
        <Link
          className="link-hover"
          to="/submit-research"
          sx={{
            ...blockLinkStyles,
            bg: "csiYellow",
            "&:hover": {
              bg: "#e7d67a",
            },
          }}
        >
          <SendIcon />
          <span>Submit research</span>
        </Link>
      </div>

      <div
        sx={{
          bg: "csiDarkGrey",
          color: "white",
          py: [rem(25), null, rem(20)],
          backgroundImage: `url(${userIllustration})`,
          backgroundRepeat: "no-repeat",
          opacity: 0.97,

          backgroundPositionX: ["100%", null, "56%"],
          backgroundPositionY: ["top", null, "center"],
          backgroundSize: ["contain", null, "contain"],
        }}
      >
        <Container
          customStyles={{
            display: "flex",
            justifyContent: "space-between",
            alignItems: ["flex-start", null, "center"],

            flexDirection: ["column", null, "row"],
          }}
        >
          <div
            sx={{
              mb: [rem(20), null, 0],
            }}
          >
            <ul
              sx={{
                display: "flex",
                variant: "typography.paragraphM",
                fontWeight: 600,
              }}
            >
              <li
                sx={{
                  mr: rem(20),
                }}
              >
                <Link to="/terms-and-conditions" className="link-hover">
                  Terms and conditions
                </Link>
              </li>
              <li>
                <Link to="/privacy-policy" className="link-hover">
                  Privacy policy
                </Link>
              </li>
            </ul>
          </div>

          <div>© Social Innovation Evidence Portal 2020</div>
        </Container>
        <Container>
          <div
            sx={{
              mt: rem(20),
              borderTop: "1px solid white",
              paddingTop: rem(20),
            }}
          >
            SEE Space is designed and hosted on Indigenous lands. We pay respect
            to the Traditional Owners of lands across Australia, their Elders,
            Ancestors, cultures and heritage, and recognise the continuing
            sovereignities of all Aboriginal and Torres Strait Islander Nations.
          </div>
        </Container>
      </div>
    </footer>
  );
};

const blockLinkStyles = {
  display: "flex",
  height: [rem(100), null, rem(277)],
  bg: "csiLightGrey",
  justifyContent: "center",
  alignItems: "center",
  width: ["100%", null, "50%"],
  span: { variant: "typography.headingXL" },
  svg: {
    mr: "5px",
    width: ["initial", null, rem(20)],
    height: ["initial", null, rem(20)],
    transform: [null, null, "translateY(2px)"],
  },
};

export default Footer;

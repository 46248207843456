/** @jsx jsx */
import { jsx } from "theme-ui";
import { useEffect } from "react";
import Container from "@components/Container";
import { useScrollPosition } from "@n8tb1t/use-scroll-position";
import { useState } from "react";
import { Link } from "gatsby";
import { rem } from "@src/theme";
import { Location } from "@reach/router";
import BackBtn from "@components/BackBtn";
import ArrowRight from "@components/icons/arrowRight";
const HeaderContent = () => {
  return (
    <Location>
      {({ location }) => (
        <div
          sx={{
            display: "flex",
            justifyContent: "space-between",
          }}
        >
          <div
            sx={{
              display: "flex",
              alignItems: "center",
            }}
          >
            <Link to="/">
              <h1
                sx={{
                  color: "white",
                  my: 0,
                  fontSize: [rem(24), null, rem(32)],
                }}
              >
                SEES
              </h1>
            </Link>
          </div>

          <div>
            <Link
              to="/explore"
              className="link-hover"
              sx={{
                height: "100%",
                display: "inline-flex",
                alignItems: "center",
                px: [rem(10), null, rem(25)],
                fontWeight: 600,
                fontSize: [rem(14), null, rem(20)],
                color: location.pathname === "/" ? "csiDarkGrey" : "white",
                background: location.pathname === "/" ? "#F4F7FB" : "initial",
              }}
              activeStyle={{ background: "#F4F7FB", color: "#20222B" }}
              partiallyActive={true}
            >
              Explore
            </Link>
            <Link
              to="/about"
              className="link-hover"
              sx={{
                height: "100%",
                display: "inline-flex",
                alignItems: "center",
                px: [rem(10), null, rem(25)],
                fontWeight: 600,
                color: "white",
                fontSize: [rem(14), null, rem(20)],
              }}
              activeStyle={{ background: "#F4F7FB", color: "#20222B" }}
            >
              About
            </Link>
            <div
              sx={{
                display: "inline-block",
                py: rem(20),
                ml: rem(20),
              }}
            >
              <Link
                to="/submit-research"
                className="link-hover"
                sx={{
                  bg: "csiYellow",
                  px: rem(14),
                  py: rem(10),
                  fontWeight: 600,
                  fontSize: [rem(14), null, rem(20)],
                  "&:hover": {
                    bg: "#e7d67a",
                    color: "initial",
                  },
                }}
              >
                Submit
                <span sx={{ display: ["none", "inline", null] }}>
                  {" "}
                  research
                </span>
              </Link>
            </div>
          </div>
        </div>
      )}
    </Location>
  );
};

const StickyContent = ({ title }) => (
  <div
    sx={{
      display: "flex",
      justifyContent: "space-between",
      color: "white",
      alignItems: "center",
      py: [rem(15), rem(15), rem(17.4)],
    }}
  >
    <div
      sx={{
        button: {
          color: "white",
          transform: "translateY(-5px)",
          fontSize: [rem(14), null, rem(18)],
          svg: {
            transform: [
              "rotate(180deg) translateY(-7px)",
              null,
              "rotate(180deg) translateY(-5px)",
            ],
          },
        },
      }}
    >
      <BackBtn />
    </div>

    <div>
      <h1
        sx={{
          fontSize: rem(24),
          my: 0,
          display: ["none", "block", null],
          letterSpacing: "-0.5px",
        }}
      >
        {title}
      </h1>
    </div>

    <button
      onClick={() => {
        window.scrollTo({ top: 0, behavior: "smooth" });
      }}
      sx={{
        transform: "translateY(-5px)",
        color: "white",
        fontSize: [rem(14), null, rem(18)],
        fontWeight: 600,
        svg: {
          transform: "rotate(270deg) translateY(2px) translateX(-6px)",
          marginRight: rem(3),
        },
      }}
    >
      Top
      <ArrowRight />
    </button>
  </div>
);

export const DefaultHeader = () => {
  return (
    <div
      sx={{
        bg: "csiDarkGrey",
      }}
    >
      <Container
        customStyles={{
          width: ["95%", null, "90%"],
        }}
      >
        <HeaderContent />
      </Container>
    </div>
  );
};
const StickyHeader = ({ title }: ComponentProps) => {
  const [sticky, setSticky] = useState(false);

  useScrollPosition(
    ({ currPos }) => {
      if (currPos.y >= 80) {
        setSticky(true);
      } else {
        setSticky(false);
      }
    },
    [],
    null,
    true,
    80
  );

  return (
    <div>
      <div
        sx={{
          position: "static",
          bg: "csiDarkGrey",
          width: "100%",
        }}
      >
        <Container
          customStyles={{
            width: ["95%", null, "90%"],
          }}
        >
          <HeaderContent />
        </Container>
      </div>

      <div
        sx={{
          visibility: sticky ? "visible" : "hidden",
          opacity: sticky ? 1 : 0,
          position: "fixed",
          bg: "csiDarkGrey",
          width: "100%",
          zIndex: 1000,
          top: 0,
          transition: "visibility 0.3s linear, opacity 0.3s linear",
        }}
      >
        <Container
          customStyles={{
            width: ["95%", null, "90%"],
          }}
        >
          <StickyContent title={title} />
        </Container>
      </div>
    </div>
  );
};

interface ComponentProps {
  title?: string;
  stickyMode?: Boolean;
}

const Main = ({ title, stickyMode }: ComponentProps) => {
  if (stickyMode) {
    return <StickyHeader title={title} />;
  } else {
    return <DefaultHeader />;
  }
};
export default Main;

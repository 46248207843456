import React from "react";

const SendIcon = () => (
<svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
<g clip-path="url(#clip0)">
<path fill-rule="evenodd" clip-rule="evenodd" d="M6.86192 8.19526L14.1953 0.861923L15.1381 1.80473L7.80473 9.13807L6.86192 8.19526Z" fill="#20222B"/>
<path fill-rule="evenodd" clip-rule="evenodd" d="M15.7533 0.246689L10.0737 16.4741L6.82827 9.17174L-0.474045 5.92627L15.7533 0.246689ZM3.14072 6.07374L7.83841 8.1616L9.92627 12.8593L13.58 2.41998L3.14072 6.07374Z" fill="#20222B"/>
</g>
<defs>
<clipPath id="clip0">
<rect width="16" height="16" fill="white"/>
</clipPath>
</defs>
</svg>

);

export default SendIcon;
